@import '@angular/material/prebuilt-themes/purple-green.css';

/* You can add global styles to this file, and also import other style files */
@import './pokeball.css';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/utilities';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
